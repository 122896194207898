import { Menu, MenuItem } from '@mui/material'
import { events } from 'config/analytics'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { useTrackEvent } from 'hooks/use-track-event'
import { CORESTREAM_PROD_QA_ROLE } from 'hooks/user-data'
import { useUserBenefitEligibility } from 'hooks/user-data/use-benefit-eligibility'
import { useRoleCheck } from 'hooks/user-data/use-role-check'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { User, UserManager } from 'oidc-client'
import { FC, lazy, Suspense, useCallback, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { accountRoutes, routesMap } from 'routes/routes-map'
import { siteInfoSelector } from 'store/site/selectors'
import { getUserManagerSelector } from 'store/user/selectors'
import { LinkBehavior } from 'theme/routing/LinkBehavior'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { clearUTMFromSessionStorage } from 'utils/cookies/utm-tracking'
import { useIsProd } from 'utils/env'
import { retry } from 'utils/promise-rety'
import { LocalStore } from 'utils/utils'

const AdminWidgetModal = lazy(() => retry(() => import('feature/admin/admin-widget/admin-widget-modal')))

interface UserMenuProps {
	anchorEl: HTMLElement | null
	handleClose: () => void
	user?: User
}
const UserMenu: FC<UserMenuProps> = ({ anchorEl, handleClose, user }: UserMenuProps) => {
	const { b4b_10023_custom_event_migration } = useFlags()
	const userManager: UserManager = useSelector(getUserManagerSelector, shallowEqual)
	const isBenefitsEligible = useUserBenefitEligibility()
	const navigate = useNavigate()
	const trackEvent = useTrackEvent()
	const track = useTrackDetailedPageEvent()
	const { hasRole } = useRoleCheck()
	const { properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const isDirectBillOnly = !properties.hasPayroll

	const [logoutClicked, setLogoutClicked] = useState<boolean>(false)
	const [showAdminModal, setShowAdminModal] = useState<boolean>(false)

	const isProd = useIsProd()
	const showAdminMenuItem = useMemo(() => !isProd() || hasRole(CORESTREAM_PROD_QA_ROLE), [hasRole, isProd])

	const handleDefaultOnClick = useCallback(
		(e): void => {
			track({
				actionType: 'click',
				elementDetails: e.target.text.toLowerCase(),
				elementName: 'your account menu cta',
				elementType: 'button',
			})

			handleClose()
		},
		[handleClose, track],
	)

	const handleProfileClicked = useCallback((): void => {
		if (b4b_10023_custom_event_migration) {
			track({
				actionType: 'click',
				elementDetails: 'profile',
				elementName: 'your account menu cta',
				elementType: 'button',
			})
		} else {
			trackEvent(events.header.myProfileClicked)
		}
		handleClose()
	}, [b4b_10023_custom_event_migration, handleClose, track, trackEvent])

	const handleLogout = useCallback(
		(e): void => {
			e.preventDefault()
			if (b4b_10023_custom_event_migration) {
				track({
					actionType: 'click',
					elementName: 'mega menu user logout cta',
					elementType: 'button',
				})
			} else {
				trackEvent(events.logoutAttempt)
			}
			setLogoutClicked(true)
			localStorage.removeItem('profileBirthDate')
			LocalStore.remove('prevRoute')

			document.cookie = 'loggedIn=;expires=Thu, 01 Jan 1970 00:00:00 UTC;'
			clearUTMFromSessionStorage()

			navigate(routesMap.logout)
			userManager.signoutRedirect()
		},
		[b4b_10023_custom_event_migration, navigate, track, trackEvent, userManager],
	)

	const handleAdminModalClose = useCallback(() => {
		setShowAdminModal(false)
		handleClose() //Needed to close the menu after the modal is closed
	}, [handleClose])

	if (!user) return null

	return (
		<>
			<Menu
				anchorEl={anchorEl}
				disableScrollLock
				keepMounted
				open={Boolean(anchorEl)}
				slotProps={{ paper: { sx: { borderRadius: '2px 2px 9px 9px', left: 'unset !important', right: 0 } } }}
				onClose={handleClose}
			>
				{isBenefitsEligible && !isDirectBillOnly && (
					<>
						<MenuItem
							component={LinkBehavior}
							href={accountRoutes.enrollments}
							sx={{
								width: '142px', // for some reason only the first item needs the width
							}}
							onClick={handleDefaultOnClick}
						>
							Enrollments
						</MenuItem>
						<MenuItem component={LinkBehavior} href={accountRoutes.deductions} onClick={handleDefaultOnClick}>
							Deductions
						</MenuItem>
					</>
				)}
				<MenuItem component={LinkBehavior} href={accountRoutes.profile} onClick={handleProfileClicked}>
					Profile
				</MenuItem>
				{showAdminMenuItem && <MenuItem onClick={() => setShowAdminModal(true)}>Admin Actions</MenuItem>}
				<MenuItem disabled={logoutClicked} onClick={handleLogout}>
					Log Out
				</MenuItem>
			</Menu>
			<Suspense>
				{showAdminModal && <AdminWidgetModal onclose={handleAdminModalClose} showModal={showAdminModal} />}
			</Suspense>
		</>
	)
}

export default UserMenu
