import { alertClasses } from '@mui/material/Alert'
import { alpha, CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

const COLORS = ['info', 'success', 'warning', 'error'] as const

// ----------------------------------------------------------------------

export function alertOverrides(): CssVarsThemeOptions['components'] {
	return {
		MuiAlert: {
			styleOverrides: {
				icon: {
					opacity: 1,
				},
				root: ({ ownerState, theme }) => {
					const standardVariant = ownerState.variant === 'standard'

					const filledVariant = ownerState.variant === 'filled'

					const outlinedVariant = ownerState.variant === 'outlined'

					const colorStyle = COLORS.map((color) => ({
						...(ownerState.severity === color && {
							// STANDARD
							...(standardVariant && {
								[`& .${alertClasses.icon}`]: {
									color: theme.vars.palette[color].main,
								},
								backgroundColor: theme.vars.palette[color].light,
								color: theme.vars.palette[color].contrastText,
							}),
							// FILLED
							...(filledVariant && {
								backgroundColor: theme.vars.palette[color].main,
								color: theme.vars.palette[color].contrastText,
							}),
							// OUTLINED
							...(outlinedVariant && {
								[`& .${alertClasses.icon}`]: {
									color: theme.palette[color].main,
								},
								backgroundColor: `rgba(${theme.vars.palette[color].mainChannel} / 0.08)`,
								border: `solid 1px ${alpha(theme.palette[color].main, 0.16)}`,
								color: theme.palette[color]['dark'],
							}),
						}),
					}))

					return [...colorStyle]
				},
			},
		},
		MuiAlertTitle: {
			styleOverrides: {
				root: ({ theme }) => ({
					fontWeight: theme.typography.fontWeightMedium,
					marginBottom: theme.spacing(0.5),
				}),
			},
		},
	}
}
