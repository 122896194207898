import { FeatureLevel } from '../api/search/types/feature-level'
import { CategoryLevel, Offer } from './offer'

export interface IAnalyticsOffer {
	brand: string
	category?: string
	color: string
	coupon: null | string
	cta: string
	description: null | string
	endDate: string
	image_url: string
	index: string
	keywords?: string
	label: string
	level: FeatureLevel
	link: string
	name: string
	objectID: string
	paychexCouponCode?: string
	paychexLink?: string
	primaryCategoryLevel: CategoryLevel
	product_id: string
	productCategory: string
	startDate: string
	tenantIDs: Array<string>
	terms: string
	title: string
}

export class AnalyticsOffer implements IAnalyticsOffer {
	brand
	category
	color
	coupon
	cta
	description
	endDate
	image_url
	index
	keywords
	label
	level
	link
	name
	objectID
	paychexCouponCode
	paychexLink
	primaryCategoryLevel
	product_id
	productCategory
	startDate
	tenantIDs
	terms
	title

	constructor(data: Offer, indexName: string) {
		this.index = indexName
		this.brand = data.vendor.name
		this.productCategory = data.category
		this.color = data.vendor.color
		this.coupon = data.code
		this.cta = data.cta
		this.description = data.description
		this.endDate = data.endDate
		this.image_url = data.image
		this.keywords = data.vendor.keywords
		this.level = data.level
		this.link = data.link
		this.name = data.title
		this.objectID = data.objectID
		this.product_id = data.objectID
		this.startDate = data.startDate
		this.tenantIDs = data.tenantIDs
		this.terms = data.terms
		this.title = data.title
		this.label = `${data.vendor.name} - ${data.title}`
		this.primaryCategoryLevel = data.primaryCategoryLevel
		this.paychexLink = data.paychexLink
		this.paychexCouponCode = data.paychexCouponCode

		if (data.parentCategory) {
			this.category = data.parentCategory
		}
	}
}
