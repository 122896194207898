import { events } from 'config/analytics'
import { MouseEvent } from 'react'
import { match } from 'ts-pattern'
import EnrollmentEvent from 'types/enrollment-event'
import { SiteInfoResponse } from 'types/tenant-site-types'

/**
 *
 * @param matchObj for now, just isEvergreen and isOE
 * @returns
 */
type PossibleEvents = { isEvergreen: boolean; isNewHire: boolean; isOE: boolean }
export const getEventForRoute = (matchObj: PossibleEvents): EnrollmentEvent =>
	match(matchObj)
		.with({ isOE: true }, () => EnrollmentEvent.OPENENROLLMENT)
		.with({ isNewHire: true }, () => EnrollmentEvent.NEWHIRE)
		.with({ isEvergreen: true }, () => EnrollmentEvent.EVERGREEN)
		.otherwise(() => EnrollmentEvent.SINGLE_PRODUCT)

export function openLinkInNewTab(e: KeyboardEvent | MouseEvent, link: string): void {
	e?.stopPropagation()

	window.open(link, '_blank')
}

export function trackEvent<D = unknown>(eventName: string, eventData?: D): void {
	window.analytics.track(eventName, eventData)
}

export function trackLoginFailure(siteInfo: SiteInfoResponse): void {
	window.analytics.identify({ anonymousId: LocalStore.get('ajs_anonymous_id'), orgID: siteInfo.organizationId })
	window.analytics.track(events.user.signInFailed, {
		label: siteInfo.organizationName,
		orgID: siteInfo.organizationId,
	})
}

// Flip a given record
export const flipRecord = (input: object) => {
	return Object.fromEntries(Object.entries(input).map(([key, value]) => [value, key]))
}

export const periodMap: Record<string, string> = {
	BW: 'bi-week',
	DP: 'pay period',
	MO: 'month',
	PP: 'pay period',
	SM: 'semi-month',
	undefined: 'pay period',
	WK: 'week',
}

export const DeductionsToFrequencyString = (deductionsPerYear: number): string => {
	switch (deductionsPerYear) {
		case 12:
			return 'month'
		case 24:
			return 'semi-month'
		case 26:
			return 'bi-week'
		case 52:
			return 'week'
		default:
			return 'pay period'
	}
}

export const periods = ['BW', 'MO', 'PP', 'SM', 'WK']

export const mappedTier = {
	CO: 'Child Only',
	EC: 'Employee and Children',
	EO: 'Employee Only',
	ES: 'Employee and Spouse',
	FA: 'Family',
	HOUSEHOLD: 'Household',
	SO: 'Spouse Only',
}

export class LocalStore {
	static get<T>(key: string): null | T {
		const item: null | string = localStorage.getItem(key)
		if (!item || item === 'undefined') return null

		const parsedItem: T = tryParseJSON(item)

		return parsedItem
	}

	static remove(key: string): void {
		localStorage.removeItem(key)
	}

	static set<T>(key: string, item: T): void {
		localStorage.setItem(key, JSON.stringify(item))
	}
}

export function debounce<F extends (...args: U) => void, U extends any[]>(
	fn: F,
	delay: number = 1000,
): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
	let timeout: ReturnType<typeof setTimeout> | undefined

	return function (this: ThisParameterType<F>, ...args: Parameters<F>): void {
		if (timeout !== undefined) {
			clearTimeout(timeout)
		}

		timeout = setTimeout(() => fn.apply(this, args), delay)
	}
}

export function numberFormatter(locale: 'en-US', opts = {}): Intl.NumberFormat {
	return new Intl.NumberFormat(locale, opts)
}

export function sleep(time) {
	return new Promise((resolve) => setTimeout(resolve, time))
}

export const removeDollarSign = (cost: string) => {
	if (cost?.length && cost.startsWith('$')) {
		return cost.substring(1)
	}

	return cost
}

export const formatCost = (cost: number | undefined, ratePeriod = 'pay period'): string => {
	// get the first 2 chars & don't replace default
	const substr = ratePeriod !== 'pay period' ? ratePeriod.substring(0, 2) : ratePeriod

	return `$${cost?.toFixed(2)}/${periodMap[substr] ?? ratePeriod}`
}
export const formatNumber = {
	currency: (num: number, format?: string, opts = {}): string =>
		numberFormatter(
			'en-US',
			Object.assign(
				{},
				{
					currency: 'USD',
					style: 'currency',
				},
				opts,
			),
		).format(num),
}

/**
 * Retrieval of disclaimer text. Needed to be separated for final submit.
 * @param name - user's name
 * @param organizationName - organization's name
 */
export function getDisclaimerText(name: string, organizationName: string): string {
	return (
		`The actual cost per pay period may vary depending on your pay schedule.\n` +
		`By completing your enrollment, you acknowledge ` +
		`that these details are correct: I, ${name}, hereby authorize ` +
		`${organizationName} to include its parents, subsidiaries, related and affiliated entities to withhold from ` +
		`my wages per pay period for the purpose of making payments towards my voluntary ` +
		`benefit elections. I further agree that, in the event my employment shall terminate, ` +
		`either voluntarily or involuntarily, the company may ` +
		`withhold any amount owed from my final pay, as permitted by applicable law. ` +
		`I represent that this authorization is executed voluntarily ` +
		`and has not been made as a condition of my continued employment.`
	)
}

export function isMobileSafari() {
	const ua = window.navigator.userAgent
	const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
	const webkit = !!ua.match(/WebKit/i)

	return iOS && webkit && !ua.match(/CriOS/i)
}

//Helper function to get the RGB values of a hex string
//https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
const hexToRgb = (hex: string) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

	return result
		? {
				b: parseInt(result[3], 16),
				g: parseInt(result[2], 16),
				r: parseInt(result[1], 16),
		  }
		: {
				b: 0,
				g: 0,
				r: 0,
		  }
}

/**
 * Converts hex into an css HSLA string
 * @param hex - hex color
 * @param saturation - addition saturation scaling (0-1)
 * @param alpha - alpha value (0-1)
 * @returns css HSL string
 *
 * modified from https://css-tricks.com/converting-color-spaces-in-javascript/
 */
export const hexToHSLA = (hex: string, saturation: number = 1, lightness: number = 1, alpha: number = 1) => {
	// Convert hex to RGB first
	let { b, g, r } = hexToRgb(hex) // Assuming hexToRgb returns an object with properties r, g, b
	// Then to HSL
	r /= 255
	g /= 255
	b /= 255
	const cmax = Math.max(r, g, b),
		cmin = Math.min(r, g, b),
		delta = cmax - cmin
	let h = 0,
		l = 0,
		s = 0

	if (delta === 0) h = 0
	else if (cmax === r) h = ((g - b) / delta) % 6
	else if (cmax === g) h = (b - r) / delta + 2
	else h = (r - g) / delta + 4

	h = Math.round(h * 60)

	if (h < 0) h += 360

	l = (cmax + cmin) / 2
	s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
	s = +(s * 100).toFixed(1)
	l = +(l * 100).toFixed(1)

	return `hsla(${h},${s * saturation}%,${l * lightness}%,${alpha})`
}

/**
 * hyphenates a sentence
 * @param str series of words to be hypenated
 * @returns series-of-words-that-are-hyphenated
 */
export const hyphenate = (str: string) => {
	return str.replace(/\s+/g, '-')
}

export const convertBoolToNumber = (value: boolean): number => (value ? 1 : 0)

export const tryParseJSON = (value: string): any => {
	try {
		return JSON.parse(value)
	} catch {
		return value // If it's not a JSON string, keep the original value
	}
}
