import { queryOptions } from '@tanstack/react-query'
import { Hit } from 'algoliasearch'
import { algoliaClient, useGetEnvIndex } from 'config/search-config'
import { Offer } from 'types/offer'

import { searchKeys } from '../keys'
import { LocalAlgoliaConfig } from '../types/algolia'
import { FeatureLevel } from '../types/feature-level'
import { mergeSearchConfig, OFFER_FILTERS, OfferType } from '../utils'
import { mapOfferHits, MappedOffer } from '../utils/mapOfferHits'
import { createSearchConfig } from '../utils/searchConfig/create-search-config'
import { OFFER_CARD_ATTRIBUTES, OfferCardFieldResult, OfferQuerySuggestionsAttributes } from '../views/offers'

/**
 * Given a list of offer ids, return a list of matching offer ids that are available to the user
 */
export function useAvailableOfferIdsByIdsQueryOptions(ids: string[]) {
	const offersIndex = useGetEnvIndex('offers')

	return queryOptions({
		queryFn: async (): Promise<Hit<OfferCardFieldResult>[]> => {
			const config = createSearchConfig(
				'offers',
				{
					attributesToRetrieve: ['objectID'],
					filters: { objectID: ids },
				},
				{ algoliaIndexName: offersIndex, omit: { facets: true } },
			)

			const response = await algoliaClient.searchForHits<OfferCardFieldResult>({
				requests: [
					{
						...config,
						attributesToHighlight: [],
						facet: undefined,
						facetQuery: undefined,
						indexName: offersIndex,
						maxFacetHits: undefined,
						type: 'default',
					},
				],
			})

			return mapOfferHits(response.results[0].hits)
		},
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: searchKeys.offers.available.byIds(ids),
	})
}

export function useOfferCardsByIdsQueryOptions(ids: string[]) {
	const offersIndex = useGetEnvIndex('offers')

	return queryOptions({
		queryFn: async (): Promise<Hit<OfferCardFieldResult>[]> => {
			const config = createSearchConfig(
				'offers',
				{
					attributesToRetrieve: ['*'],
					filters: { objectID: ids },
				},
				{ algoliaIndexName: offersIndex, omit: { facets: true } },
			)

			const response = await algoliaClient.searchForHits<OfferCardFieldResult>({
				requests: [
					{
						...config,
						attributesToHighlight: [],
						facet: undefined,
						facetQuery: undefined,
						indexName: offersIndex,
						maxFacetHits: undefined,
						type: 'default',
					},
				],
			})

			return mapOfferHits(response.results[0].hits)
		},
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: searchKeys.offers.card.byIds(ids),
	})
}

export function useOfferCardsByLevelQueryOptions(
	level: FeatureLevel | FeatureLevel[],
	offerType: OfferType = 'All',
	config?: Partial<{ attributesToRetrieve: string[] } & LocalAlgoliaConfig>,
) {
	const offersIndex = useGetEnvIndex('offers')

	const filters = {
		[OFFER_FILTERS.LEVEL]: level,
	}
	if (offerType !== 'All') {
		filters[OFFER_FILTERS.OFFER_TYPE] = offerType
	}

	return queryOptions({
		queryFn: async (): Promise<MappedOffer<{ [key: string]: any } & OfferCardFieldResult>[]> => {
			const merged = createSearchConfig(
				'offers',
				mergeSearchConfig(
					{
						attributesToRetrieve: OFFER_CARD_ATTRIBUTES as unknown as string[],
						filters,
					},
					config,
				),
				{ algoliaIndexName: offersIndex, omit: { facets: true } },
			)

			const response = await algoliaClient.searchForHits<OfferCardFieldResult>({
				requests: [
					{
						...merged,
						facet: undefined,
						facetQuery: undefined,
						indexName: offersIndex,
						maxFacetHits: undefined,
						type: 'default',
					},
				],
			})

			return mapOfferHits(response.results[0].hits) as MappedOffer<
				{ isMemberExclusiveOffer?: boolean | undefined } & OfferCardFieldResult
			>[]
		},
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: searchKeys.offers.card.byLevel(level),
	})
}

export function useOfferDetailsByIdQueryOptions(id: string) {
	const offersIndex = useGetEnvIndex('offers')

	return queryOptions({
		queryFn: async (): Promise<Hit<Offer>> => {
			const config = createSearchConfig(
				'offers',
				{
					attributesToRetrieve: ['*'],
					filters: { objectID: id },
				},
				{ algoliaIndexName: offersIndex, omit: { facets: true } },
			)

			const response = await algoliaClient.searchForHits<Offer>({
				requests: [
					{
						...config,
						facet: undefined,
						facetQuery: undefined,
						indexName: offersIndex,
						maxFacetHits: undefined,
						type: 'default',
					},
				],
			})

			const hit = response.results[0].hits[0]
			if (!hit) throw new Error('Offer not found')

			return mapOfferHits([hit])[0]
		},
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: searchKeys.offers.detail.byId(id),
		staleTime: 5 * 60 * 1000,
	})
}

export function useOfferDetailsByIdsQueryOptions(ids: string[]) {
	const offersIndex = useGetEnvIndex('offers')

	return queryOptions({
		queryFn: async (): Promise<Hit<Offer>[]> => {
			const config = createSearchConfig(
				'offers',
				{
					filters: { objectID: ids },
				},
				{ algoliaIndexName: offersIndex, omit: { facets: true } },
			)

			const response = await algoliaClient.searchForHits<Offer>({
				requests: [
					{
						...config,
						facet: undefined,
						facetQuery: undefined,
						indexName: offersIndex,
						maxFacetHits: undefined,
						type: 'default',
					},
				],
			})

			return mapOfferHits(response.results[0].hits)
		},
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: searchKeys.offers.detail.byIds(ids),
	})
}

export function useOfferQuerySuggestionsQueryOptions(query: string, config?: Partial<LocalAlgoliaConfig>) {
	const offersIndex = 'offers_query_suggestions'

	return queryOptions({
		queryFn: async (): Promise<Hit<OfferQuerySuggestionsAttributes>[]> => {
			const merged = createSearchConfig(
				'offers_query_suggestions',
				mergeSearchConfig(
					{
						attributesToRetrieve: ['*'],
						filters: {},
						numericFilters: [],
					},
					config,
				),
				{ algoliaIndexName: offersIndex, ignoreBaseConfig: true, omit: { facets: true } },
			)

			const response = await algoliaClient.searchForHits<OfferQuerySuggestionsAttributes>({
				requests: [
					{
						...merged,
						facet: undefined,
						indexName: offersIndex,
						maxFacetHits: undefined,
						query: query,
						type: 'default',
					},
				],
			})

			return response.results[0].hits
		},
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: searchKeys.offers.querySuggestions.byQuery(query),
	})
}
