import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from 'config/tanstack-query'
import { useBootIntercom } from 'hooks/intercom'
import { useScript } from 'hooks/use-script'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Maintenance from 'pages/maintenance'
import { useEffect } from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import { DataLoaderCapableRoutes, NeedingToBeMigratedRoutes } from 'routes/all-routes'
import AppLayout from 'routes/app-layout'
import { actions as benefitPlansActions } from 'store/benefit-plans/slice'
import { actions as enrollmentWizardActions } from 'store/enrollment-wizard/slice'
import { actions as siteActions } from 'store/site/slice'
import { store } from 'store/store'
import { runCacheBuster } from 'utils/run-cache-buster'

import { useAnalayticsScript } from '../utils/analyticScript'
import { useAppContext } from './contexts/app-context'
import { ThemeRegistration } from './ThemeRegistration'

export const App = () => {
	const { showClientMaintenancePage } = useFlags()
	if (showClientMaintenancePage) {
		return <Maintenance />
	}

	return <AppContent />
}

function AppContent() {
	const { b4b_pdpv3, wrp2596 } = useFlags()
	const { siteInfo } = useAppContext()

	useEffect(() => {
		store.dispatch(enrollmentWizardActions.setWrp2596(wrp2596))
		store.dispatch(benefitPlansActions.setB4BPdpv3(b4b_pdpv3))
	}, [b4b_pdpv3, wrp2596])

	useEffect(() => {
		runCacheBuster()
	}, [])

	useEffect(() => {
		if (siteInfo) {
			store.dispatch(siteActions.getSiteInfoSuccess(siteInfo))
		}
	}, [siteInfo])

	useBootIntercom(siteInfo)

	useScript(useAnalayticsScript(), true)

	const newDataLoaderCapabaleRoutes = createRoutesFromElements(DataLoaderCapableRoutes, [1])

	const router = createBrowserRouter(
		[
			{
				children: [
					...newDataLoaderCapabaleRoutes,
					{
						element: <NeedingToBeMigratedRoutes />,
						path: '*',
					},
				],
				element: <AppLayout />,
				path: '*',
			},
		],
		{
			future: {
				v7_fetcherPersist: true,
				v7_normalizeFormMethod: true,
				v7_partialHydration: true,
				v7_relativeSplatPath: true,
			},
		},
	)

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeRegistration properties={siteInfo.properties}>
				<ReactQueryDevtools initialIsOpen={false} />
				<RouterProvider router={router} />
			</ThemeRegistration>
		</QueryClientProvider>
	)
}
