import { lazy } from 'react'

import { SkeletonComponentsMap } from './types'
import { usePropertyToTypeMapping } from './utils/use-property-to-type-mapping'
import withDeserializedProps from './utils/with-deserialized-props'

export const pdpModulesMapping = {
	Calculators: addModule('Calculators', () => import('./components/calculators/calculators')),
	ClientNotes: addModule('ClientNotes', () => import('./components/client-notes/client-notes')),
	Disclaimer: addModule('Disclaimer', () => import('./components/disclaimer/disclaimer')),
	FAQs: addModule('FAQs', () => import('./components/faqs/faqs')),
	Header: addModule('Header', () => import('../components/header/header')),
	MoreResources: addModule('MoreResources', () => import('./components/more-resources/more-resources')),
	PlanHighlights: addModule('PlanHighlights', () => import('./components/plan-highlights/plan-highlights')),
	RealLifeExample: addModule('RealLifeExample', () => import('./components/real-life-example/real-life-example')),
	RightForMe: addModule('RightForMe', () => import('./components/right-for-me/right-for-me')),
	Testimonials: addModule('Testimonials', () => import('./components/testimonials/testimonials')),
	WhatsCovered: addModule('WhatsCovered', () => import('./components/whats-covered/whats-covered')),
}

// Skeleton mappings for components
export const pdpSkeletonsMapping: SkeletonComponentsMap = {
	Calculators: addModule('CalculatorsSkeleton', () => import('./components/calculators/calculators-skeleton')),
	ClientNotes: addModule('ClientNotesSkeleton', () => import('./components/client-notes/client-notes-skeleton')),
	Disclaimer: addModule('DisclaimerSkeleton', () => import('./components/disclaimer/disclaimer-skeleton')),
	FAQs: addModule('FaqsSkeleton', () => import('./components/faqs/faqs-skeleton')),
	Header: addModule('HeaderSkeleton', () => import('../components/header/header-skeleton')),
	MoreResources: addModule(
		'MoreResourcesSkeleton',
		() => import('./components/more-resources/more-resources-skeleton'),
	),
	PlanHighlights: addModule(
		'PlanHighlightsSkeleton',
		() => import('./components/plan-highlights/plan-highlights-skeleton'),
	),
	RealLifeExample: addModule(
		'RealLifeExampleSkeleton',
		() => import('./components/real-life-example/real-life-example-skeleton'),
	),
	RightForMe: addModule('RightForMeSkeleton', () => import('./components/right-for-me/right-for-me-skeleton')),
	Testimonials: addModule('TestimationsSkeleton', () => import('./components/testimonials/testimonials-skeleton')),
	WhatsCovered: addModule('WhatsCoveredSkeleton', () => import('./components/whats-covered/whats-covered-skeleton')),
}

// Generic function to add a lazy-loaded component
export function addModule<T extends Record<string, React.ComponentType<any>>, K extends keyof T>(
	exportName: K,
	importPath: () => Promise<T>,
) {
	const LazyComponent = lazy(() =>
		importPath().then((module) => {
			if (!(exportName in module)) {
				throw new Error(`Export "${String(exportName)}" not found in module.`)
			}

			return { default: module[exportName] as T[K] }
		}),
	)

	return withDeserializedProps(LazyComponent, usePropertyToTypeMapping)
}
