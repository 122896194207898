import { getBenefitContentVersion } from 'feature/benefit-plan/utils/get-benefit-content-version'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'
import { BenefitPlanV2, MarketingContent, ModularMarketingContent } from 'store/benefit-plans/types'

export type ClientMarketingContent = {
	cardText: string
	cardTextHeadline: string
	ctaUrl: string
	endDate: string
	headerMedia: string
	headerMediaIsVideo: boolean
	startDate: string
	termsAndConditionsText: string
}

/**
 * Returns Marketing Content Content based on a flag and if Modular Content Exists
 * @param bp Benefit Plan
 * @returns Marketing Content Content
 */
export const useMarketingContent = (bp: BenefitPlanV2 | null) => {
	const { b4b_pdpv3 } = useFlags()

	return useMemo(() => {
		return getMarketingContent(bp, { b4b_pdpv3 })
	}, [b4b_pdpv3, bp])
}

/**
 * exposed as function for nested loop style calls that aren't available via hooks
 */
export function getMarketingContent(bp: BenefitPlanV2 | null, { b4b_pdpv3 }: { b4b_pdpv3: boolean }) {
	const modularMarketingContent = bp?.modularMarketingContent

	const version = getBenefitContentVersion(bp, { b4b_pdpv3 })

	return version === 'pdpv3'
		? getModularContent(modularMarketingContent)
		: getLegacyContent(bp?.effectiveMarketingContent)
}

function getLegacyContent(content: MarketingContent | undefined): ClientMarketingContent {
	return {
		cardText: content?.productTemplate?.cardText ?? '',
		cardTextHeadline: content?.productTemplate?.cardTextHeadline ?? '',
		ctaUrl: content?.ctaUrl ?? content?.productTemplate?.ctaUrl ?? '',
		endDate: content?.endDate ?? '',
		headerMedia: content?.productTemplate?.headerMedia ?? '',
		headerMediaIsVideo: content?.productTemplate?.headerMediaIsVideo ?? false,
		startDate: content?.startDate ?? '',
		termsAndConditionsText: content?.productTemplate?.termsAndConditionsText ?? '',
	}
}

function getModularContent(content: ModularMarketingContent | undefined): ClientMarketingContent {
	return {
		cardText: content?.cardText ?? '',
		cardTextHeadline: content?.cardTextHeadline ?? '',
		ctaUrl: content?.ctaUrl ?? '',
		endDate: new Date(content?.endDate ? content.endDate * 1000 : '').toString(),
		headerMedia: content?.headerMedia ?? '',
		headerMediaIsVideo: content?.headerMediaIsVideo ?? false,
		startDate: new Date(content?.startDate ? content.startDate * 1000 : '').toString(),
		termsAndConditionsText: content?.termsAndConditionsText ?? '',
	}
}
