import { Snackbar as MuiSnackbar } from '@mui/material'
import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { ERROR, INFO, SUCCESS, WARNING } from 'types/status-types'

import { Alert } from './alert'

interface SnackBarProps {
	anchorOrigin?: any
	message: string
	onClose?: any
	onExited?: any
	open: boolean
	shouldAutoHide?: boolean
	status?: typeof ERROR | typeof INFO | typeof SUCCESS | typeof WARNING
}

const Snackbar: FC<SnackBarProps> = (props: SnackBarProps) => {
	const autoHide = props.shouldAutoHide ? 5000 : null // 5s in ms
	const [localOpen, setLocalOpen] = useState(props.open ?? false)
	const handleClose = (_?: Event | SyntheticEvent, reason?: string): null | void => {
		if (props.onClose) props.onClose()
		if (reason === 'clickaway') return null
		setLocalOpen(false)
	}

	useEffect(() => {
		setLocalOpen(props.open)
	}, [props.open])

	return (
		<MuiSnackbar
			anchorOrigin={props.anchorOrigin}
			autoHideDuration={autoHide}
			open={localOpen}
			TransitionProps={{ onExited: props.onExited }}
			onClose={handleClose}
		>
			<div>
				<Alert severity={props.status} onClose={handleClose}>
					{props.message}
				</Alert>
			</div>
		</MuiSnackbar>
	)
}

Snackbar.defaultProps = {
	anchorOrigin: { horizontal: 'center', vertical: 'top' },
	shouldAutoHide: true,
}

export default Snackbar
