import { Close } from '@mui/icons-material'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	Slide,
	SlideProps,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import { cx } from 'class-variance-authority'
import { forwardRef, memo, PropsWithChildren, ReactElement, ReactNode, Ref } from 'react'

import styles from './generic-modal-v2.module.scss'

interface ClassesProp {
	container?: string
	title?: string
}

interface GenericModalV2Props {
	actions?: ReactNode
	centerTitle?: boolean
	classes?: ClassesProp
	dialogProps?: Partial<DialogProps>
	disableBackdropClick?: boolean
	disableEscapeKeyDown?: boolean
	disableMobileFullScreen?: boolean
	fullWidth?: boolean
	hideCloseIcon?: boolean
	hideTitle?: boolean
	id?: string
	maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs'
	onClose: () => void
	showModal: boolean
	subTitle?: ReactNode | string
	title?: ReactNode | string
}

const Transition = forwardRef(function Transition(
	props: { children?: ReactElement<any, any> } & SlideProps,
	ref: Ref<unknown>,
) {
	return <Slide ref={ref} direction='up' {...props} />
})

const GenericModalV2 = (props: PropsWithChildren<GenericModalV2Props>): JSX.Element => {
	const theme = useTheme()
	const mobileFullScreen = useMediaQuery(theme.breakpoints.down('md'))

	const handleClose = (_, reason) => {
		if (props.disableBackdropClick && reason === 'backdropClick') {
			return
		}

		props.onClose()
	}

	return (
		<Dialog
			{...props.dialogProps}
			className={cx(styles['generic-modal-v2'], props.classes?.container)}
			disableEscapeKeyDown={props.disableEscapeKeyDown}
			fullScreen={props.disableMobileFullScreen ? false : mobileFullScreen}
			fullWidth={props.fullWidth}
			maxWidth={props.maxWidth}
			open={props.showModal}
			PaperProps={{ id: props.id }}
			TransitionComponent={mobileFullScreen ? Transition : undefined}
			onClose={handleClose}
		>
			{props.hideTitle ? null : (
				<DialogTitle className={props.classes?.title}>
					<div
						className={cx(styles['header'], {
							[styles['header-centered']]: props.centerTitle,
						})}
					>
						<div>
							{typeof props.title === 'string' ? (
								<Typography variant='h3'>
									<strong>{props.title}</strong>
								</Typography>
							) : (
								props.title
							)}
							{typeof props.subTitle === 'string' ? (
								<p style={{ paddingTop: 'var(--space-xl)' }}>{props.subTitle}</p>
							) : (
								props.subTitle
							)}
						</div>
						{props.hideCloseIcon ? null : (
							<IconButton
								aria-label='close'
								size='large'
								sx={(theme) => ({
									'@media (min-width:600px)': {
										top: -2,
									},
									color: theme.palette.grey['900'],
									position: 'absolute',
									right: -12,
									top: -4,
								})}
								onClick={props.onClose}
							>
								<Close />
							</IconButton>
						)}
					</div>
				</DialogTitle>
			)}
			<DialogContent style={{ position: 'relative' }}>
				<>
					{props.hideTitle && !props.hideCloseIcon && (
						<IconButton
							aria-label='close'
							className={styles['hidden-title-close-button']}
							size='large'
							onClick={props.onClose}
						>
							<Close />
						</IconButton>
					)}
					{props.children}
				</>
			</DialogContent>
			{props.actions && (
				<DialogActions className={styles['generic-modal-v2--actions-container']}>{props.actions}</DialogActions>
			)}
		</Dialog>
	)
}

GenericModalV2.defaultProps = {
	maxWidth: 'md',
}

export default memo(GenericModalV2)
