import { BenefitPlanCoverageDetails } from 'hooks/elections/types'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { UserEnrollmentRes } from 'types/user-enrollment'

import { EnrollmentStatusUtilities } from './enrollment-status'

export function getCoverageDetailsFromEnrollments(
	effectiveEnrollment: undefined | UserEnrollmentRes,
	latestEnrollment: UserEnrollmentRes,
	benefitPlan: BenefitPlanV2 | null,
): BenefitPlanCoverageDetails {
	const isPending = latestEnrollment?.isModifiedByLtdAutoPend || effectiveEnrollment?.isModifiedByLtdAutoPend

	const eoiPending =
		benefitPlan?.shouldRequireEvidenceOfInsurability &&
		isPending &&
		(EnrollmentStatusUtilities.isEnrolled(latestEnrollment?.statusCode) ||
			EnrollmentStatusUtilities.isEnrolled(effectiveEnrollment?.statusCode))

	if (!effectiveEnrollment)
		return {
			cancelled: false,
			eoiLink: benefitPlan?.evidenceOfInsurabilitySubmissionLink,
			eoiPending,
			futurePlanEffectiveDate: '',
			hasFutureUpdates: false,
			latestEnrollmentEffectiveDate: new Date(),
		}

	const latestEnrollmentEffectiveDate = new Date(latestEnrollment?.planEffectiveDate ?? '')

	const isEnrollmentCancelled =
		latestEnrollment?.statusCode === EnrollmentStatus.CANCELED && latestEnrollmentEffectiveDate > new Date()

	const isEnrollmentEdited =
		effectiveEnrollment?.statusCode === EnrollmentStatus.ENROLLED &&
		latestEnrollment?.statusCode === EnrollmentStatus.ENROLLED

	const hasFutureUpdates = isEnrollmentEdited && new Date(latestEnrollment?.planEffectiveDate) > new Date()
	const futurePlanEffectiveDate = isEnrollmentCancelled || hasFutureUpdates ? latestEnrollment?.planEffectiveDate : ''

	return {
		cancelled: isEnrollmentCancelled,
		eoiLink: benefitPlan?.evidenceOfInsurabilitySubmissionLink,
		eoiPending,
		futurePlanEffectiveDate,
		hasFutureUpdates,
		latestEnrollmentEffectiveDate,
	}
}
