import { Tier } from 'api/benefit-elections/types'
import { PetEnrollmentDetails } from 'api/enrollments'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'

/**
 * Goal is to represent what the user sees when reviewing the cart,
 * because all items are shown in a single table
 */
export type BaseCartItem = {
	amount: number
	benefitPlanId: number
	cartItemType: CartItemType
	/**
	 * Displays under the 'PLAN' in the review table. e.g. 'Privacy Armor'
	 * The Coverage Label is returned from Rates api
	 */
	coverageLabel: string
	effectiveDate: Date
	frequency: string // TODO:pdp enum? Enum listed in enrollments wasn't respected. I saw "BW" submitted
	lookupKey?: string
	shouldRequireEvidenceOfInsurability?: boolean
	statusCode: EnrollmentStatus
	tierLabel: string // only used for display
	totalCoverageText?: string
}

/**
 * Used for generic native benefits that are enrolled through us. 98% of our native benefits will use this
 */
export type BenefitItem = {
	coverage: string
	coverageId: string
	disclaimerText: string
	enrollmentId?: string
	hasAcceptedAdditionalDisclaimer: boolean
	hasAcceptedTermsAndConditions: boolean
	programId: number
	questions: QuestionAnswer[]
	tier?: Tier
	tierId: string
} & BaseCartItem

/**
 * We use a Union Type to handle the different product types a user can enroll in.
 */
export type CartItem = BenefitItem & NwPetItem

/**
 * These types only exist at compile time, so there is no option of doing a typeof() on the object.
 * Therefore each cart item must state what type it is, so components can determine what type of cart item they have.
 */
export type CartItemType = 'BenefitItem' | 'NwPetItem'

export type NwPetItem = {
	confirmedDisclaimer: string
	pets: PetEnrollmentDetails[]
	postalCode?: number | string
	programId: number
} & BaseCartItem

//Hook
//Maps the data depending on NW / Native

export type QuestionAnswer = {
	fieldDefinitionId?: number
	fieldId?: string
	name?: string
	questionId?: string
	value: boolean | string
}

export const CartItemMappingKeys: Record<'pets' | 'questions', string[][]> = {
	pets: [['petGuid']],
	questions: [['fieldDefinitionId'], ['questionId']],
}
/**
 * Mapping to the Enrollments DTO
 * (~) questions
 * (+) isSingleProductFlow
 * (+) programEnrollmentId (looks like its always an empty string?)
 * (-) amount (not sent to the api)
 */

/**
 * Mapping to the Bennies DTO
 * (~) questionAnswers
 * (+) dependents
 * (+) pets
 * (+) eventNotes (describe the qle option)
 * (+) postalCode
 */
