import { CircularProgress } from '@mui/material'
import useRecentOECompletion from 'hooks/use-recent-oe-completion'
import { useEnrollmentData } from 'hooks/user-data'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { benefitRoutes, routesMap } from 'routes/routes-map'
import { userEnrollmentOverviewStatusSelector } from 'store/enrollments/selectors'
import { match } from 'ts-pattern'
import { SUCCESS } from 'types/status-types'
import { LOCAL_STORAGE_KEYS } from 'utils/storage/local-storage'

import styles from './post-login-feature-router.module.css'

type PostLoginProps = {
	children: any
}

// Component to handle post-login logic and rendering
export const PostLoginFeatureRouter = ({ children }: PostLoginProps) => {
	const [hasCompletedPostLoginLogic, setHasCompletedPostLoginLogic] = useState<boolean>(
		!!localStorage.getItem(LOCAL_STORAGE_KEYS.CompletedPostLoginLogic),
	)

	// Handle the completion of post-login logic
	const handleCompletion = useCallback(() => {
		setHasCompletedPostLoginLogic(true)
	}, [])

	// If post-login logic has been completed, render children immediately
	if (hasCompletedPostLoginLogic) return children

	// Otherwise, render PostLoginLogic component with a loading fallback
	return (
		<Suspense fallback={<FullPageLoader />}>
			<PostLoginLogic onComplete={handleCompletion} />
			{!hasCompletedPostLoginLogic ? <FullPageLoader /> : children}
		</Suspense>
	)
}

const FullPageLoader = () => (
	<div className={styles['loading-container']}>
		<CircularProgress />
	</div>
)

interface PostLoginLogicProps {
	onComplete: () => void
}

// Component to handle the actual post-login logic
function PostLoginLogic({ onComplete }: PostLoginLogicProps) {
	const { isAllDataLoaded, isOE } = useEnrollmentData()
	const hasCompletedOE = useRecentOECompletion()
	const userEnrollmentOverviewStatus = useSelector(userEnrollmentOverviewStatusSelector)
	const { b4b_15094_post_login_landing } = useFlags()
	const ldClient = useLDClient()
	const navigate = useNavigate()
	const [route, setRoute] = useState<null | string>(null)

	useEffect(() => {
		// Only proceed if all data is loaded and user enrollment overview status is successful
		if (isAllDataLoaded && userEnrollmentOverviewStatus === SUCCESS) {
			new Promise<void>((resolve) => {
				const context = ldClient?.getContext()
				// Ensure we have a valid user context
				if (context && 'user' in context && context.user.employeeId !== 'DEFAULT') {
					// Determine the appropriate route based on feature flags and user state
					const newRoute = match<{ hasCompletedOE?: boolean; isOE?: boolean; landing: string }>({
						hasCompletedOE,
						isOE,
						landing: b4b_15094_post_login_landing,
					})
						.with({ landing: '/home' }, () => routesMap.home)
						.with({ hasCompletedOE: false, isOE: true, landing: '/wizard' }, () => benefitRoutes.wizard.base)
						.with({ landing: '/wizard' }, () => routesMap.home)
						.with({ hasCompletedOE: false, isOE: true, landing: '/wizard/intro' }, () => benefitRoutes.wizard.intro)
						.with({ landing: '/wizard/intro' }, () => routesMap.home)
						.otherwise(() => routesMap.home)

					setRoute(newRoute)
					resolve()
				}
			})
		} else {
			// If data isn't loaded or status isn't successful, return an empty promise
			new Promise<void>(() => {})
		}
	}, [b4b_15094_post_login_landing, hasCompletedOE, isAllDataLoaded, isOE, ldClient, userEnrollmentOverviewStatus])

	useEffect(() => {
		// Once a route is determined, navigate to it and mark post-login logic as completed
		if (route) {
			navigate(route)
			localStorage.setItem(LOCAL_STORAGE_KEYS.CompletedPostLoginLogic, 'true')
			// Delay the onComplete call to ensure navigation has finished
			setTimeout(onComplete, 100)
		}
	}, [navigate, onComplete, route])

	return null
}
