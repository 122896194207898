import { cx } from 'class-variance-authority'
import { ForwardedRef, forwardRef, ReactElement } from 'react'

// important: use WithRef, not WithoutRef
type ButtonProps = {
	icon?: React.ReactNode
} & React.ComponentPropsWithRef<'button'>

/**
 * Usage:
 * https://twitter.com/heyImMapleLeaf/status/1724340641952371108
 *
 */

/**
 * a utility for making non generic components forwardRefs automatically.
 *
 * This is useful for components that require styling overrides, but also need to be able to forward refs.
 */
export function autoRef<Props extends { ref?: RefType }, RefType>(fc: {
	(props: Props): null | ReactElement
	displayName?: string
}) {
	const AutoRef = (props: Props, ref: ForwardedRef<RefType>) => fc({ ...props, ref })
	AutoRef.displayName = fc.displayName || fc.name || 'AutoRef'

	return forwardRef(AutoRef)
}
// using function keyword to give the component a name
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Button = autoRef(function Button({ children, className, icon, ...props }: ButtonProps) {
	return (
		<button className={cx('bg-blue-500 p-4 rounded', className)} type='button' {...props}>
			{icon}
			{children}
		</button>
	)
})
