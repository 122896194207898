import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { WysiwigContent } from 'components/wysiwig-content/wysiwig-content'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { FC, ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { siteInfoSelector } from 'store/site/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'

import './faq-accordion.css'
import { AddIcon, RemoveIcon } from './styles'
import { FAQAccordionProps } from './types'

const FAQAccordion: FC<FAQAccordionProps> = (props: FAQAccordionProps): ReactElement => {
	const [expanded, setExpanded] = useState<boolean>(false)
	const textComponent = typeof props.answer === 'string' ? 'p' : 'div'
	const id = self.crypto.randomUUID()
	const siteInfo: SiteInfoResponse = useSelector(siteInfoSelector)

	const removePhoneNumber = (text: string) => {
		if (!siteInfo.properties.excludeCSPhoneNumber) return text

		return text.replace(`call ${siteInfo.properties.customerCarePhone}`, 'click to chat below')
	}

	const track = useTrackDetailedPageEvent()

	return (
		<div className='faq-accordion'>
			<Accordion expanded={expanded} square onChange={(): void => setExpanded(!expanded)}>
				<AccordionSummary
					aria-controls={`panel-${id}-content`}
					expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
					id={`panel-${id}-header`}
				>
					<p className='accordion-text accordion-summary-text'>{props.question}</p>
				</AccordionSummary>
				<AccordionDetails>
					<Typography className='accordion-text' component={textComponent}>
						{typeof props.answer === 'string' ? (
							<WysiwigContent
								content={removePhoneNumber(props.answer)}
								onLinkClick={(url) => {
									track({
										actionType: 'click',
										elementDetails: url,
										elementName: 'faq link',
										elementType: 'hyperlink',
										pageName: 'faq',
									})
								}}
							/>
						) : (
							<>{props.answer}</>
						)}
					</Typography>
				</AccordionDetails>
			</Accordion>
		</div>
	)
}

FAQAccordion.defaultProps = {}

export default FAQAccordion
