// types control the page experience and are used to determine which components to render
export const StepTypesList = ['plan', 'info', 'eoi', 'receipt', 'morecoverage'] as const
export type BaseStep = {
	id: number
	label: string
	link: string
	type: StepType
}

export type Step = BaseStep

export type StepsState = {
	completed: number[]
	currentStepIndex: number
	locked: number[]
	steps: Step[]
	visited: number[]
}

export type StepType = (typeof StepTypesList)[number]

export const initialState: StepsState = {
	completed: [],
	currentStepIndex: 0,
	locked: [],
	steps: [],
	visited: [],
}

export type StepStatuses = Pick<StepsState, 'completed' | 'visited'>
