 
import { Draft } from 'immer'
import { SearchStore } from 'state/store'
import { createZustandSlice } from 'state/utils/create-slice'

import {
	ConfiguredIndices,
	FacetConfig,
	IndexParams,
	IndexState,
	SearchSliceActions,
	SearchSliceData,
	SearchStatus,
} from './types'

export const initialState: SearchSliceData = {
	indices: {},
	query: '',
}

 
// @ts-nocheck
export const createSearchSlice = createZustandSlice<SearchStore, SearchSliceData, SearchSliceActions>(
	'search',
	(set) => ({
		...initialState,

		clearFacetValues: (index: ConfiguredIndices, facet: string) =>
			set((state) => {
				const current = getSafeIndex(state.indices, index)
				current.params.facets[facet] = []
			}, 'clearFacetValues'),

		initializeIndices: (indices: ConfiguredIndices[]) =>
			set((state) => {
				state.indices = indices.reduce((acc, index) => {
					acc[index] = createIndexState(index)

					return acc
				}, {} as Partial<Record<ConfiguredIndices, IndexState>>)
			}, 'initializeIndices'),

		setFacetConfig: (index: ConfiguredIndices, facet: string, config: FacetConfig) =>
			set((state) => {
				const current = getSafeIndex(state.indices, index)
				if (!current.params.facetConfig) {
					current.params.facetConfig = {}
				}
				current.params.facetConfig[facet] = config
			}, 'setFacetConfig'),

		setFacets: (index: ConfiguredIndices, facets: IndexParams['facets']) =>
			set((state) => {
				const current = getSafeIndex(state.indices, index)
				current.params.facets = facets
			}, 'setFacets'),

		setFacetValues: (index: ConfiguredIndices, facet: string, values: string[]) =>
			set((state) => {
				const current = getSafeIndex(state.indices, index)
				current.params.facets[facet] = values
			}, 'setFacetValues'),

		setIndexStatus: (index: ConfiguredIndices, status: SearchStatus) =>
			set((state) => {
				const current = getSafeIndex(state.indices, index)
				current.status = status
			}, 'setIndexStatus'),

		setQuery: (query: string) =>
			set((state) => {
				state.query = query
			}, 'setQuery'),

		toggleFacetValue: (index: ConfiguredIndices, facet: string, value: string) =>
			set((state) => {
				const current = getSafeIndex(state.indices, index)

				if (!current.params.facets[facet]) {
					current.params.facets[facet] = [value]

					return
				}

				current.params.facets[facet] = current.params.facets[facet].includes(value)
					? current.params.facets[facet].filter((v) => v !== value)
					: [...current.params.facets[facet], value]
			}, 'toggleFacetValue'),

		updateIndexParams: (index: ConfiguredIndices, params: Partial<IndexParams>) =>
			set((state) => {
				const current = getSafeIndex(state.indices, index)
				current.params = { ...current.params, ...params }
			}, 'updateIndexParams'),
	}),
	initialState,
)
function createIndexState(index: string): IndexState {
	return {
		indexName: index,
		params: {
			facetConfig: {},
			facets: {},
			filters: {},
			numericFilters: [],
		},
		status: 'unconfigured',
	}
}

function getSafeIndex(indices: Draft<Record<string, IndexState>>, index: string): Draft<IndexState> {
	let current = indices[index]

	if (!current) {
		indices[index] = createIndexState(index)
		current = indices[index]
	}

	return current
}
