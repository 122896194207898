// ----------------------------------------------------------------------

export function pxToRem(value: number) {
	return `${value / 14}rem`
}

export function remToPx(value: string) {
	return Math.round(parseFloat(value) * 14)
}

export function responsiveFontSizes({ lg, md, sm }: { lg: number; md: number; sm: number }) {
	return {
		'@media (min-width:1200px)': {
			fontSize: pxToRem(lg),
		},
		'@media (min-width:600px)': {
			fontSize: pxToRem(sm),
		},
		'@media (min-width:900px)': {
			fontSize: pxToRem(md),
		},
	}
}

declare module '@mui/material/styles' {
	interface TypographyVariants {
		fontSecondaryFamily: React.CSSProperties['fontFamily']
		fontWeightSemiBold: React.CSSProperties['fontWeight']
	}
}

export const primaryFont = 'var(--font-family)'
export const secondaryFont = 'var(--font-family)'

// ----------------------------------------------------------------------

export const typography = {
	body1: {
		// lineHeight: 1.5,
		fontSize: pxToRem(14),
		// ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
	},
	body2: {
		// lineHeight: 1.5,
		fontSize: pxToRem(14),
	},
	button: {
		// lineHeight: 1.5,
		fontSize: pxToRem(14),
		fontWeight: 700,
		textTransform: 'capitalize',
	},
	caption: {
		// lineHeight: 1.5,
		fontSize: pxToRem(12),
	},
	fontFamily: primaryFont,
	fontSecondaryFamily: secondaryFont,
	fontWeightBold: 700,
	fontWeightMedium: 500,
	fontWeightRegular: 400,
	fontWeightSemiBold: 600,
	h1: {
		fontFamily: secondaryFont,
		// lineHeight: 1.5,
		fontSize: pxToRem(34.84),
		fontWeight: 300,
		...responsiveFontSizes({ lg: 42.72, md: 42.72, sm: 34.84 }),
	},
	h2: {
		fontFamily: secondaryFont,
		// lineHeight: 1.5,
		fontSize: pxToRem(24.19),
		fontWeight: 700,
		...responsiveFontSizes({ lg: 27.34, md: 27.34, sm: 24.19 }),
	},
	h3: {
		fontFamily: secondaryFont,
		// lineHeight: 1.5,
		fontSize: pxToRem(20.16),
		fontWeight: 400,
		...responsiveFontSizes({ lg: 21.88, md: 21.88, sm: 20.16 }),
	},
	['h3-bold']: {
		fontFamily: secondaryFont,
		fontSize: pxToRem(21.88),
		fontWeight: 700,
		lineHeight: 1.5,
		...responsiveFontSizes({ lg: 21.88, md: 21.88, sm: 20.16 }),
	},
	h4: {
		fontFamily: secondaryFont,
		// lineHeight: 1.5,
		fontSize: pxToRem(18),
		fontWeight: 600,
		...responsiveFontSizes({ lg: 20, md: 20, sm: 18 }),
	},
	['h4-body']: {
		fontFamily: secondaryFont,
		// lineHeight: 1.5,
		fontSize: pxToRem(18),
		fontWeight: 400,
		...responsiveFontSizes({ lg: 20, md: 20, sm: 18 }),
	},
	h5: {
		fontFamily: secondaryFont,
		// lineHeight: 1.5,
		fontSize: pxToRem(15),
		fontWeight: 600,
		...responsiveFontSizes({ lg: 17.5, md: 17.5, sm: 15 }),
	},
	['h5-body']: {
		fontFamily: secondaryFont,
		// lineHeight: 1.5,
		fontSize: pxToRem(17.5),
		fontWeight: 400,
		...responsiveFontSizes({ lg: 17.5, md: 17.5, sm: 17.5 }),
	},
	h6: {
		fontFamily: secondaryFont,
		// lineHeight: 28 / 18,
		fontSize: pxToRem(18),
		fontWeight: 600,
		...responsiveFontSizes({ lg: 18, md: 18, sm: 18 }),
	},
	['h6-body']: {
		fontFamily: secondaryFont,
		fontSize: pxToRem(16),
		fontWeight: 400,
		lineHeight: 1.5,
		...responsiveFontSizes({ lg: 16, md: 15, sm: 14 }),
	},
	overline: {
		// lineHeight: 1.5,
		fontSize: pxToRem(12),
		fontWeight: 700,
		textTransform: 'uppercase',
	},
	subtitle1: {
		fontFamily: secondaryFont,
		fontSize: pxToRem(16),
		fontWeight: 600,
		lineHeight: 1.5,
		...responsiveFontSizes({ lg: 16, md: 15, sm: 14 }),
	},
	subtitle2: {
		// lineHeight: 1.5,
		fontSize: pxToRem(14),
		fontWeight: 600,
	},
} as const
