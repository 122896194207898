import { usePipeSortingByProductType } from 'hooks/benefit-plans/product-type/use-sorting-by-product-type'
import { useAppSelector } from 'hooks/redux'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectDisplayEndDate, selectIsOE, selectOEWindow, selectSplitPlans } from 'store/benefit-plans/selectors'
import { NON_NATIVE } from 'store/benefit-plans/types'
import { isUserProfileLoadedSelector } from 'store/user/selectors'
import { match } from 'ts-pattern'
import EnrollmentEvent from 'types/enrollment-event'
import { SUCCESS } from 'types/status-types'
import { IsNotAccessoryPlan } from 'utils/benefit-plans'
import { pipe } from 'utils/pipe'
import { formatDateMonthDayYear } from 'utils/util-dates'

import { useNewHirePlans } from '.'
import { EnrollmentData, EnrollmentType } from './types'
import { usePipeConsolidateGrandfatherAndParentPlans } from './use-consolidate-grandfather-and-parent-plans'
import { usePipeThirdPartyData } from './use-third-party-enrollment-data'
import { usePipeRecommendationData } from './usePipeRecommendationData'

const initEnrollmentData: EnrollmentData = {
	anytimePlans: [],
	displayEndDate: undefined,
	enrollmentEndDate: '',
	enrollmentEvent: undefined,
	enrollmentPlans: [],
	enrollmentPlansToDisplay: [],
	enrollmentStartDate: '',
	enrollmentType: undefined,
	hasNative: false,
	hasNonNative: false,
	isAllDataLoaded: false,
	isNewHire: false,
	isOE: false,
}

/**
 * Calls the NewHire Endpoint to determine it's status
 * @returns If user is New Hire, the EndDate, and the New Hire plans
 */
export const useEnrollmentData = (): EnrollmentData => {
	const newHireData = useNewHirePlans()

	const oeDisplayEndDate = useSelector(selectDisplayEndDate)
	const splitPlans = useSelector(selectSplitPlans)
	const isOECheck = useSelector(selectIsOE)
	const oeWindow = useSelector(selectOEWindow)

	const { apply: applyThirdParty } = usePipeThirdPartyData({
		sourceListsOfPlans: [newHireData.newHirePlans, splitPlans.anytimePlans, splitPlans.oePlans],
	})
	const { apply: applyGrandfatherAndParentPlans } = usePipeConsolidateGrandfatherAndParentPlans()
	const { apply: applySortingByProductType } = usePipeSortingByProductType()
	const {
		apply: applyPipeRecommendationData,
		subscription: { isFetched: isRecommendationFetched },
	} = usePipeRecommendationData()

	const result = useMemo(
		() =>
			pipe(
				applyThirdParty,
				applyGrandfatherAndParentPlans,
				applySortingByProductType,
				applyPipeRecommendationData,
			)([newHireData.newHirePlans, splitPlans.anytimePlans, splitPlans.oePlans]),
		[
			applyPipeRecommendationData,
			applyThirdParty,
			applyGrandfatherAndParentPlans,
			applySortingByProductType,
			newHireData.newHirePlans,
			splitPlans.anytimePlans,
			splitPlans.oePlans,
		],
	)

	const [newHirePlans, anytimePlans, oePlans] = result

	// Loading status
	const { benefitPlansFetchStatus, oeWindowsFetchStatus } = useAppSelector((state) => state.benefitPlans.requestStates)
	const isUserProfileLoaded = useSelector(isUserProfileLoadedSelector)
	const isAllDataLoaded = useMemo(() => {
		return (
			benefitPlansFetchStatus === SUCCESS &&
			isUserProfileLoaded &&
			oeWindowsFetchStatus === SUCCESS &&
			newHireData.isFetched &&
			!!isRecommendationFetched === true
		)
	}, [
		benefitPlansFetchStatus,
		isRecommendationFetched,
		isUserProfileLoaded,
		newHireData.isFetched,
		oeWindowsFetchStatus,
	])

	const newHirePlanIds = useMemo(() => newHirePlans.map((x) => x.benefitPlanId), [newHirePlans])

	/**
	 * Determines which enrollment data to return, based on if in OE, if NewHire, or neither
	 * IMPORTANT: OE will take precedence over New Hire
	 */
	return match({ isAllDataLoadedMatch: isAllDataLoaded, isNewHireMatch: newHireData.isNewHire, isOEMatch: isOECheck })
		.with({ isAllDataLoadedMatch: true, isOEMatch: true }, () => ({
			...initEnrollmentData,
			anytimePlans,
			displayEndDate: oeDisplayEndDate,
			enrollmentEndDate: oeWindow?.endDate ?? '',
			enrollmentEvent: EnrollmentEvent.OPENENROLLMENT,
			enrollmentPlans: oePlans,
			enrollmentPlansToDisplay: oePlans.filter(IsNotAccessoryPlan),
			enrollmentStartDate: oeWindow ? formatDateMonthDayYear(oeWindow?.startDate) : '',
			enrollmentType: EnrollmentType.oe,
			hasNative: splitPlans.hasNativeInOe,
			hasNonNative: oePlans.find((x) => x.enrollmentMethod === NON_NATIVE) ? true : false,
			isAllDataLoaded,
			isOE: true,
		}))
		.with({ isAllDataLoadedMatch: true, isNewHireMatch: true }, () => ({
			...initEnrollmentData,
			anytimePlans: anytimePlans.filter((x) => !newHirePlanIds.includes(x.benefitPlanId)),
			displayEndDate: formatDateMonthDayYear(newHireData.newHireEndDate.toDateString()),
			enrollmentEndDate: newHireData.newHireEndDate.toLocaleDateString(),
			enrollmentEvent: EnrollmentEvent.NEWHIRE,
			enrollmentPlans: newHirePlans,
			enrollmentPlansToDisplay: newHirePlans.filter(IsNotAccessoryPlan),
			enrollmentStartDate: formatDateMonthDayYear(newHireData.newHireStartDate.toLocaleDateString()),
			enrollmentType: EnrollmentType.newHire,
			hasNative: newHireData.hasNative,
			hasNonNative: newHireData.hasNonNative,
			isAllDataLoaded,
			isNewHire: true,
		}))
		.otherwise(() => ({
			...initEnrollmentData,
			enrollmentStartDate: formatDateMonthDayYear(newHireData.newHireStartDate.toLocaleDateString()),
			enrollmentType: EnrollmentType.closed,
			isAllDataLoaded,
		}))
}
