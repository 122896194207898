import BlockUI from 'components/block-ui'
import { useTrackEligiblityEvaluations } from 'feature/home/hooks/use-track-event-evaluations'
import { PostLoginFeatureRouter } from 'feature/post-login-feature-router/post-login-feature-router'
import { useTrackLoginEvent } from 'hooks/analytics/login/useTrackLoginEvent'
import { useAppSelector } from 'hooks/redux'
import { useEnrollmentData } from 'hooks/user-data'
import { useUserBenefitEligibility } from 'hooks/user-data/use-benefit-eligibility'
import { useEffectOnceSessionStorageCondition } from 'hooks/utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { userEnrollmentOverviewSelector, userEnrollmentOverviewStatusSelector } from 'store/enrollments/selectors'
import { siteInfoSelector } from 'store/site/selectors'
import { isLoggedInSelector } from 'store/user/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { LocalStore } from 'utils/utils'

import { routesMap } from './routes-map'

function AuthorizedRoute(): JSX.Element {
	useTrackEligiblityEvaluations()
	const hasEligibleBenefits = useUserBenefitEligibility()
	const userEnrollmentOverview = useAppSelector(userEnrollmentOverviewSelector)
	const userEnrollmentStatus = useAppSelector(userEnrollmentOverviewStatusSelector)
	const { isAllDataLoaded, isOE } = useEnrollmentData()

	/**
	 * Update Intercom if the user is actively enrolled in a native plan
	 * Only do this once per session
	 */
	useEffectOnceSessionStorageCondition(
		() => {
			if (
				isOE &&
				Object.values(userEnrollmentOverview).some(
					(enrollment) => enrollment[0].isNative && enrollment[0].planEffectiveDate <= new Date().toISOString(),
				)
			) {
				window.Intercom('update', { actively_enrolled: true })
			}
		},
		'intercom.enrollment-message',
		userEnrollmentStatus === 'success' && window.Intercom && isAllDataLoaded,
	)

	LocalStore.remove('prevRoute')
	teardownUrlStorage()

	if (!hasEligibleBenefits) {
		return <Outlet />
	}

	return (
		<PostLoginFeatureRouter>
			<Outlet />
		</PostLoginFeatureRouter>
	)
}

function ProtectedRoute(): JSX.Element {
	const isLoggedIn: boolean = useSelector(isLoggedInSelector)

	return isLoggedIn ? <AuthorizedRoute /> : <UnauthorizedRoute />
}

function teardownUrlStorage() {
	LocalStore.remove('returnUrl')
	LocalStore.remove('query')
}

export default ProtectedRoute

function UnauthorizedRoute(): JSX.Element {
	const location = useLocation()
	const { b4b_marketingcontent_preview } = useFlags()
	const siteInfo: SiteInfoResponse = useAppSelector(siteInfoSelector)
	const trackSSOLogin = useTrackLoginEvent()
	const [loading, setLoading] = useState<boolean>(true)

	LocalStore.set('prevRoute', location.pathname + location.search)
	const isPreview = b4b_marketingcontent_preview && location.search.includes('marketingContentId')

	if (siteInfo.properties.ssoEnabled && siteInfo.properties.ssoAuthUrl) {
		if (!isPreview) {
			trackSSOLogin({
				actionType: 'on leave',
				elementName: 'sso-login',
				elementType: 'page',
				pageName: 'login',
			})
		}
		setTimeout(() => setLoading(false), 2000)

		if (!loading) window.location.href = isPreview ? routesMap.adminLogin : siteInfo.properties.ssoAuthUrl

		return <BlockUI message='Redirecting you to login page...' shouldBlock showSpinner />
	}

	return <Navigate replace to={isPreview ? routesMap.adminLogin : '/'} />
}
