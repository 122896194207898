import { isAfter, isWithinInterval, subDays } from 'date-fns'
import { BenefitPlanV2, MarketingContent, OEWindow } from 'store/benefit-plans/types'
import { match, P } from 'ts-pattern'

export type FormatBenefitParams = {}

export type KeyDateParams = {
	bp: BenefitPlanV2
	effectiveDates: Record<number, string>
	oeWindow: null | OEWindow | undefined
}

export const formatBenefitPlan = (payload: FormatBenefitParams & KeyDateParams): BenefitPlanV2 => {
	const keyDate = getKeyDate(payload)

	const effectiveMarketingContent = getMarketingContent(payload, keyDate)

	return {
		...payload.bp,
		effectiveMarketingContent,
		isNative: getIsNative(payload),
		keyDate,
	}
}

/**
 * apply calculated field isNative to the benefit plan
 */
function getIsNative(payload: KeyDateParams): boolean {
	return payload.bp.enrollmentMethod.toLowerCase() === 'native'
}

function getKeyDate({
	bp,
	effectiveDates,
	oeWindow,
}: {
	bp: BenefitPlanV2
	effectiveDates: Record<number, string>
	oeWindow: null | OEWindow | undefined
}): Date | undefined {
	// This is to convert the time in ms (UTC) to local
	const convertFromUTC = (date: Date): Date => new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
	const bpStartDate = convertFromUTC(new Date(bp.startDate * 1000))
	const bpEndDate = convertFromUTC(new Date(bp.endDate * 1000))
	// if in OE window use electionsEffective date from oeWindow obj else use plan date from effectiveDates api
	let keyDate: Date = match({
		effectiveDate: effectiveDates?.[bp.benefitPlanId],
		endDate: bpEndDate,
		oePlan: oeWindow?.oeWindowPlans?.[bp.benefitPlanId],
		oeWindow,
		startDate: bpStartDate,
	})
		.with(
			{
				oePlan: P.when((plan) => plan != null && plan.useElectionsEffectiveDate),
				oeWindow: { electionsEffectiveDate: P.select() },
			},
			(electionsEffectiveDate) => new Date(electionsEffectiveDate),
		)
		.with(
			{ effectiveDate: P.not(P.nullish), oePlan: P.nullish },
			{ effectiveDate: P.not(P.nullish), oePlan: { useElectionsEffectiveDate: false } },
			({ effectiveDate }) => new Date(effectiveDate),
		)
		.with({ effectiveDate: P.select() }, (effectiveDate) => new Date(effectiveDate))
		.run()

	// if benefit plan minEffectiveDate is greater then keyDate update keyDate
	if (bp.minEffectiveDate && isAfter(new Date(bp.minEffectiveDate), keyDate)) {
		keyDate = new Date(bp.minEffectiveDate)
	}

	return keyDate
}

function getMarketingContent(payload: KeyDateParams, keyDate: Date | undefined) {
	const isNative = getIsNative(payload)

	const epoch = new Date(8.64e15)
	const today = new Date()

	// use keyDate (aka effective date of the plan) to get current marketing content
	const effectiveMarketingContent = payload.bp.marketingContent
		// change to date comparision - make sure newest start date is first
		.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
		.find((mc) => {
			const mcStartDate = new Date(mc.startDate)
			// as of pdpv3 - we will ignore end date and only show start date for legacy content while we transition
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const mcEndDate = subDays(new Date(mc.endDate), 1) // subtract 1 day to make it exclusive

			// Use today instead of keydate for non-native plans
			if (!isNative || !keyDate) {
				return isWithinInterval(today, { end: epoch, start: mcStartDate })
			}

			// startDate <= effectivedate AND EndDate > effectivedate
			return isWithinInterval(keyDate, { end: epoch, start: mcStartDate })
		})

	return effectiveMarketingContent ?? ({} as MarketingContent)
}
